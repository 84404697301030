import React from "react";
import {PaymentStatus} from "../checkout/types";
import {useGetConfigurationsQuery, useGetOrderStatusQuery} from "../checkout/api";
import {useParams} from "react-router-dom";
import {TurnTable} from "./TurnTable";

const PaymentErrorPage = () => {

    let {id} = useParams();

    const configurations = useGetConfigurationsQuery().currentData;

    const bp = configurations?.businessProfile;

    const orderId = id as string;
    const order = useGetOrderStatusQuery(orderId)

    const current =  order.currentData;

    const fortuneTeller = current?.fortuneTeller;
    const replyDate = current?.replyDate;



    const remoteFailure = !order.isSuccess && !order.isLoading;

    const status = current?.status;


    return (
        <div className="cartomante-detail">
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            {(bp === 'ISA' || bp === 'C24') && <div id="account-detail" style={{minHeight: "500px"}}>
                                <div className="card">
                                    <div className="card-header bg_light_pan" style={{textAlign: "center"}}>
                                        <h3><strong>
                                            ATTENZIONE
                                        </strong></h3>
                                    </div>
                                    <div className="card-body">
                                        {fortuneTeller && status && status === PaymentStatus.CARTOMANTE_NOT_AVAILABLE && <>
                                            <p className="CorpoMessaggio_Risposta center"><strong
                                                style={{fontSize: "28px", color: "darkgreen"}}>Acquisto
                                                completato</strong></p>

                                            <p className="CorpoMessaggio_Risposta center"><strong>Purtroppo il
                                                cartomante in
                                                questo momento non è disponibile.</strong></p>

                                            {fortuneTeller.isTurn  && <TurnTable cartomante={fortuneTeller.name}/>}

                                        </>}


                                        {status === PaymentStatus.ALREADY_USED && <>
                                            <p className="CorpoMessaggio_Risposta center"><strong
                                                style={{fontSize: "28px", color: "darkgreen"}}>Consulto
                                                richiesto</strong></p>

                                            <p className="CorpoMessaggio_Risposta center"><strong>Hai già inviato la tua
                                                richiesta, ti preghiamo di pazientare affinche il cartomante risponda
                                                alla
                                                tua richiesta</strong></p>

                                            <p className="CorpoMessaggio_Risposta center">
                                                <strong>
                                                    <u>Ricordati di controllare la cartella della "posta indesiderata" o
                                                        "spam"!</u>
                                                </strong>
                                                <br/>

                                            </p>
                                        </>}

                                        {status === PaymentStatus.WAIT_FOR_CONFIRMATION && <>
                                            <p className="CorpoMessaggio_Risposta center"><strong
                                                style={{fontSize: "28px", color: "darkgreen"}}>Consulto in attesa di conferma</strong></p>

                                            <p className="CorpoMessaggio_Risposta center"><strong>Riceverai un email quando il pagamento sarà
                                                verificato</strong></p>

                                            <p>{'Riferiento: ' + orderId}</p>

                                            <p className="CorpoMessaggio_Risposta center">
                                                <strong>
                                                    <u>Ricordati di controllare la cartella della "posta indesiderata" o
                                                        "spam"!</u>
                                                </strong>
                                                <br/>

                                            </p>
                                        </>}


                                        {status === PaymentStatus.REPLIED && <>
                                            <p className="CorpoMessaggio_Risposta center">
                                                <strong style={{fontSize: "28px", color: "darkgreen"}}>Consulto utilizzato</strong>
                                            </p>

                                            <p className="CorpoMessaggio_Risposta center">
                                                <strong>{`Hai già ricevuto in data ${replyDate} il tuo consulto.`}</strong>
                                            </p>
                                            <p className="CorpoMessaggio_Risposta center">
                                                <strong>Controlla la tua email ilcartomante dovrebbe averti risposto</strong></p>

                                            <p className="CorpoMessaggio_Risposta center">
                                                <strong>
                                                    <u>Ricordati di controllare la cartella della "posta indesiderata" o
                                                        "spam"!</u>
                                                </strong>
                                                <br/>
                                            </p>

                                        </>}

                                        {status === PaymentStatus.NOT_FOUND &&
                                            <>
                                                <p className="CorpoMessaggio_Risposta center"><strong
                                                    style={{fontSize: "28px", color: "darkgreen"}}>Consulto non
                                                    trovato</strong></p>

                                                <p className="CorpoMessaggio_Risposta center"><strong>Non abbiamo
                                                    trovato nessun
                                                    consulto.</strong></p>
                                            </>
                                        }


                                        {status === PaymentStatus.NOT_FOUND &&
                                            <>
                                                <p className="CorpoMessaggio_Risposta center"><strong
                                                    style={{fontSize: "28px", color: "darkgreen"}}>Consulto non
                                                    trovato</strong></p>

                                                <p className="CorpoMessaggio_Risposta center"><strong>Non abbiamo
                                                    trovato nessun
                                                    consulto.</strong></p>
                                            </>
                                        }

                                        {(status === PaymentStatus.ERROR || remoteFailure) &&
                                            <>
                                                <p className="CorpoMessaggio_Risposta center"><strong
                                                    style={{fontSize: "28px", color: "darkgreen"}}>Ops... si è
                                                    verificato un
                                                    errore</strong></p>


                                                <p className="center">Riferimento:${orderId}</p>

                                                <p className="CorpoMessaggio_Risposta center"><strong>Contatta
                                                    l'assistenza
                                                    clienti</strong></p>
                                            </>
                                        }

                                        <br/>


                                    </div>

                                </div>
                            </div>}

                            {(bp === 'MYC') && <div id="account-detail" style={{minHeight: "500px"}}>
                                <div className="card">
                                    <div className="card-body">
                                        {fortuneTeller && status && status === PaymentStatus.CARTOMANTE_NOT_AVAILABLE && <>
                                            <p className="CorpoMessaggio_Risposta center"><strong
                                                style={{fontSize: "28px", color: "darkgreen"}}>Pagamento completato</strong></p>

                                            <p className="CorpoMessaggio_Risposta center"><strong>Il cartomante in non è disponibile riprova più tardi.</strong></p>


                                        </>}


                                        {status === PaymentStatus.ALREADY_USED && <>
                                            <p className="CorpoMessaggio_Risposta center"><strong
                                                style={{fontSize: "28px", color: "darkgreen"}}>Non disponibile</strong></p>

                                            <p className="CorpoMessaggio_Risposta center"><strong>Risulta che hai già contattao il cartomante, ti preghiamo  di attendere la ricezione della risposta</strong></p>

                                            <p className="CorpoMessaggio_Risposta center">
                                                <strong>
                                                    <u>Controlla la cartella "posta indesiderata"</u>
                                                </strong>
                                                <br/>

                                            </p>
                                        </>}

                                        {status === PaymentStatus.WAIT_FOR_CONFIRMATION && <>
                                            <p className="CorpoMessaggio_Risposta center"><strong
                                                style={{fontSize: "28px", color: "darkgreen"}}>Pagamento non confermato</strong></p>

                                            <p className="CorpoMessaggio_Risposta center"><strong>Quando il pagamento sarà completo riceverai una conferma sul tuo indirizzo usato in fase di
                                                acquisto</strong></p>

                                            <p className="CorpoMessaggio_Risposta center">
                                                <strong>Se pensi che qualcosa non ha funzionando manda un' email al nostro supporto comunicando questo codice </strong>
                                                <p>{'Codice: ' + orderId}</p>
                                            </p>

                                            <p className="CorpoMessaggio_Risposta center">
                                                <strong>
                                                    <u>Controlla la cartella "posta indesiderata"</u>
                                                </strong>
                                                <br/>

                                            </p>
                                        </>}


                                        {status === PaymentStatus.REPLIED && <>
                                            <p className="CorpoMessaggio_Risposta center">
                                                <strong style={{fontSize: "28px", color: "darkgreen"}}>Consulto utilizzato in precedenza</strong>
                                            </p>

                                            <p className="CorpoMessaggio_Risposta center">
                                                <strong>{`Il  ${replyDate} hai ricevuto risposta.`}</strong>
                                            </p>

                                            <p className="CorpoMessaggio_Risposta center">
                                                <strong>
                                                    <u>Controlla la cartella "posta indesiderata"</u>
                                                </strong>
                                                <br/>
                                            </p>

                                        </>}


                                        {(status === PaymentStatus.ERROR || status === PaymentStatus.NOT_FOUND  || remoteFailure) &&
                                            <>
                                                <p className="CorpoMessaggio_Risposta center"><strong
                                                    style={{fontSize: "28px", color: "darkgreen"}}>Si è verificato un problema</strong></p>

                                                <p className="CorpoMessaggio_Risposta center">
                                                    <strong>Se pensi che qualcosa non ha funzionando manda un' email al nostro supporto comunicando questo codice </strong>
                                                    <p>{'Codice: ' + orderId}</p>
                                                </p>
                                            </>
                                        }

                                        <br/>


                                    </div>

                                </div>
                            </div>}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentErrorPage;
