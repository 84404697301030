import {api} from "../../api";
import {IpInfoResponse, TrackRequest} from "./types";

export function getIpInfo():Promise<IpInfoResponse>  {
    return Promise.resolve({ip: '0.0.0.0'} as IpInfoResponse);
}


const auditsApi = api.injectEndpoints({
    endpoints: (build) => ({

        audit: build.mutation<void, Partial<TrackRequest>>({

            query(data) {
                const { ...body } = data
                return {
                    url: `/checkout/audits`,
                    method: 'POST',
                    body,
                }
            },
        })

    }),
    overrideExisting: false,
})

export const {
    useAuditMutation,
} = auditsApi;


