import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {PaymentStatus} from "../checkout/types";
import {useGetOrderStatusQuery} from "../checkout/api";
import AppLayout from "./AppLayout";
import {useTracker} from "../tracking/tracking.hook";


const CheckoutLayout: React.FC = () => {

    const {id} = useParams();

    const navigate = useNavigate();

    const order = useGetOrderStatusQuery(id as string);

    const tracker = useTracker();

    useEffect(() => {


        if (!order.isSuccess && !order.isLoading) {
            tracker.track('checkout', 'payment_error', id)
            navigate('/payment-error/' + id);
        }

        if (order.isSuccess && order.currentData!.status === PaymentStatus.ERROR) {
            tracker.track('checkout', 'payment_error', JSON.stringify({id,status:order.currentData!.status}));
            navigate('/payment-error/' + id);
        }

        if (order.isSuccess && order.currentData!.status === PaymentStatus.WAIT_FOR_CONFIRMATION) {
            tracker.track('checkout', 'payment_error', JSON.stringify({id,status:order.currentData!.status}));
            navigate('/payment-error/' + id);
        }

        if (order.isSuccess && order.currentData!.status === PaymentStatus.CARTOMANTE_NOT_AVAILABLE) {
            tracker.track('checkout', 'payment_error', JSON.stringify({id,status:order.currentData!.status}));
            navigate('/payment-error/' + id);
        }

        if(order.isSuccess && order.currentData!.status===PaymentStatus.CREATED){
            tracker.track('checkout', 'payment_page_view', JSON.stringify({id}));
        }



    }, [id, navigate, order])

    return (
        <AppLayout fortuneTeller={order.currentData?.fortuneTeller.name}/>
    );

}

export default CheckoutLayout;
