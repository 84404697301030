import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Form, Formik, FormikValues} from "formik";
import validate from "./utils";
import {CheckoutFormPageValues} from "./CheckoutFormPageValues";
import StipePayment from "../payment/StipePayment";
import PaypalPayment from "../payment/PaypalPayment";
import {PaymentFormValues} from "./CheckoutPage";
import {useFinalizeOrderMutation, useGetOrderQuery} from "./api";
import {useTracker} from "../tracking/tracking.hook";

const CheckoutForm: React.FC = () => {

    let {id} = useParams();

    const [sendingOrder, setSendingOrder] = useState<boolean>(false);

    const tracker = useTracker();

    const [finalizeOrder] = useFinalizeOrderMutation()

    const onPaymentConfirmed = (values: FormikValues)=> {

        tracker.track('checkout', 'start_confirm', id)
        setSendingOrder(true);
        const result = finalizeOrder({
            id: id as string,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            fullName: values.firstName + ' ' + values.lastName
        }).unwrap();

        result.then(function () {
            tracker.track('checkout', 'success_confirm', id)
            document.location = '/thank-you/'+id;
        }).catch(() => {
            tracker.track('checkout', 'failure_confirm', id)
            document.location = '/payment-error/'+id;
        })
    }


    const initialValues: PaymentFormValues = { email: '',firstName:'',lastName:'' };

    const response = useGetOrderQuery(id as string);



    return (
        <Formik validateOnChange={true} validateOnBlur={true} validateOnMount={true} validate={validate} initialValues={initialValues} onSubmit={(values, actions) => {
        }}>
            {({values ,isValid,setFieldValue}) => (
                <Form>

                    <CheckoutFormPageValues setFieldValue={setFieldValue}/>

                    <div style={{marginTop: "20px"}}>
                        <PaypalPayment checkoutId={id as string} disabled={sendingOrder} onPaymentConfirmed={() => {
                            tracker.track('checkout', 'paypal_confirm', id)
                            tracker.track('checkout', 'payment_confirm', id)
                            onPaymentConfirmed(values);
                        }}/>
                    </div>

                    {
                        response?.currentData?.publicKeys?.STRIPE && <>

                            <p className="instruction" style={{marginTop: "20px"}}>Oppure paga con Carta di debito o credito</p>
                            <div style={{marginTop: "10px"}}>
                                <StipePayment checkoutId={id as string} onPaymentConfirmed={() => {
                                    tracker.track('checkout', 'stripe_confirm', id)
                                    tracker.track('checkout', 'payment_confirm', id)
                                    onPaymentConfirmed(values);
                                }} email={values.email} disabled={!isValid || sendingOrder}/>
                            </div>
                        </>
                    }

                </Form>
            )}

        </Formik>
    );

}

export default CheckoutForm;
