import React, {useEffect, useState} from 'react';

import {useAddPaymentMethodMutation, useGetOrderQuery} from "../checkout/api";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import {PaymentMethod} from "../checkout/types";
import {useFormikContext} from "formik";
import {useTracker} from "../tracking/tracking.hook";

interface Props {
    checkoutId: string;
    disabled:boolean;
    onPaymentConfirmed: any;
}

const PaypalPayment: React.FC<Props> = ({checkoutId,disabled,onPaymentConfirmed}) => {

    const response = useGetOrderQuery(checkoutId);
    const { validate,values } = useFormikContext();

    const tracker = useTracker();

    const [addPaymentMethod,{ isLoading: isUpdating, data }] = useAddPaymentMethodMutation()

    return (
        <>
            {response.currentData && response.currentData.publicKeys.PAYPAL &&
				<PayPalScriptProvider options={{"client-id": response.currentData.publicKeys.PAYPAL, locale:"it_IT", currency:"EUR"}}>
					<PayPalButtons
						createOrder={(data, actions) => {
                            console.log(data);
                            tracker.track('checkout', 'paypal_pay_start', checkoutId)
                            return addPaymentMethod({
                                paymentMethod: PaymentMethod.PAYPAL,
                                id: checkoutId
                            }).unwrap()
                                .then((res) => {
                                    return res.ref;
                                })
                                .then((res) => {
                                    tracker.track('checkout', 'paypal_pay_select', checkoutId)
                                    return res;
                                });
                        }}
                        onClick={(data,actions) =>{
                            try {
                                tracker.track('checkout', 'paypal_pay_start_click', checkoutId)
                                tracker.track('checkout', 'payment_button_click', checkoutId)
                                validate?.(values);
                                actions.resolve().then();
                                tracker.track('checkout', 'paypal_pay_end_click', checkoutId)
                            } catch (e) {
                                tracker.track('checkout', 'paypal_pay_error', checkoutId)
                                actions.reject().then();
                            }
                        }
                        }
						onApprove={function (data, actions) {
                            tracker.track('checkout', 'paypal_pay_approved', checkoutId)
                            return onPaymentConfirmed();
                        }}
                        fundingSource={"paypal"} disabled={disabled} style={{
                        layout: "vertical",
                        shape: 'pill',
                        color: 'gold',
                        label: 'buynow',
                    }}/>
				</PayPalScriptProvider>
            }
        </>
    );
}


export default PaypalPayment;
