import React, {useMemo} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useGetOrderQuery} from "./api";
import {useTracker} from "../tracking/tracking.hook";
import {PaymentStatus} from "./types";
import CheckoutForm from "./CheckoutForm";
import CheckoutServicePreview from "./CheckoutServicePreview";


export interface PaymentFormValues {
    email: string;
    firstName: string;
    lastName: string;
}

const CheckoutPage: React.FC = () => {

    const {id} = useParams();

    const [searchParams] = useSearchParams()

    const tracker = useTracker();

    const navigate = useNavigate();

    const orderResp = useGetOrderQuery(id as string)


    if (orderResp.isSuccess && [PaymentStatus.COMPLETED,PaymentStatus.ALREADY_USED,PaymentStatus.REPLIED].includes(orderResp.currentData!.order.status)) {
        tracker.track('checkout', 'payment_completed',id)
        navigate('/thank-you/' + id);
    }

    const order = orderResp.currentData?.order;

    const service = orderResp.currentData?.service;

    const fortuneTeller = orderResp.currentData?.fortuneTeller;

    useMemo(() => {
        tracker.track('checkout', 'page_loaded', id)
    }, [id]);


    const cartomanteImage = () => {
        return fortuneTeller?.imageUri ? fortuneTeller?.imageUri :  `/images/cartomanti/${fortuneTeller?.name.toLowerCase()}.png`
    }

    const doBack = () => {
        tracker.track('checkout', 'back', id)
        back();
    }

    const back = () => {
        if (searchParams.has("backUrl"))
            window.location.href = decodeURI(searchParams.get("backUrl")!)
        else
            navigate(-1);
    }

    return (
        <div className={"main_content"}>
            <div className={"container section checkout-page"}>
                {order && fortuneTeller && order.status === PaymentStatus.CREATED && <main id="main">
					<div className={"row"}>
						<div className={"col-md-6 col-xs-12"}>
							<div className={"App-Overview"}>
								<header className="Header" style={{backgroundColor: "rgb(255, 255, 255)"}}>
									<div>
										<div
											className="Header-business flex-item width-grow flex-container align-items-center">
											<a className="Link Header-businessLink Link--primary" href="#"
											   onClick={doBack}>
												<div style={{position: "relative"}}>
													<div className="justify-content-center">
														<div>
															<div className="row col-7 no-gutters">
																<div className="col-2">
																	<div className="Header-backArrowContainer"
																		 style={{opacity: 1, transform: "none"}}
																		 onClick={doBack}>
																		<svg
																			className="InlineSVG Icon Header-backArrow mr2 Icon--sm"
																			focusable="false"
																			width="12" height="12" viewBox="0 0 16 16">
																			<path
																				d="M3.417 7H15a1 1 0 0 1 0 2H3.417l4.591 4.591a1 1 0 0 1-1.415 1.416l-6.3-6.3a1 1 0 0 1 0-1.414l6.3-6.3A1 1 0 0 1 8.008 2.41z"
																				fillRule="evenodd"/>
																		</svg>
																	</div>
																</div>

																<div className="col-3">
																	<img height="30px" width="30px"
																		 className="HeaderImage-imageIcon"
																		 alt="icon" src={cartomanteImage()}/>
																</div>
																<div className="col-3">
																	<h1 className="Header-businessLink-name Text Text-color--gray800 Text-fontSize--14 Text-fontWeight--500 Text--truncate">
                                                                        {fortuneTeller.name}
																	</h1>
																</div>


															</div>

														</div>
														<div className="col-6"/>

													</div>
												</div>
											</a>
										</div>
									</div>
								</header>
								<main id={"main"}>
									<div id={"checkout"}>
										<CheckoutForm/>
									</div>
								</main>
							</div>
						</div>
						<div className={"col-md-6 col-xs-12"}>
                            {service &&
								<CheckoutServicePreview service={service} showPrice={true} showInfo={false} promo={fortuneTeller.promo}/>}
						</div>
					</div>
				</main>}
            </div>
        </div>


    );
}

export default CheckoutPage;
