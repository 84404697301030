import {
    CardElement
} from '@stripe/react-stripe-js'
import React, {useState} from 'react'
import {useStripe, useElements} from '@stripe/react-stripe-js';
import {useAddPaymentMethodMutation} from "../checkout/api";
import {PaymentMethod} from "../checkout/types";
import {useFormikContext} from "formik";
import {useTracker} from "../tracking/tracking.hook";

interface Props {
    checkoutId:string;
    email:string;
    disabled: boolean;
    onPaymentConfirmed:any;
    amount:number;
}

export const StripePaymentElement: React.FC<Props> = ({onPaymentConfirmed,amount,checkoutId,email,disabled}) => {

    const stripe = useStripe();
    const elements = useElements();

    const tracker = useTracker();

    const { submitForm } = useFormikContext();

    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [addPaymentMethod,{ isLoading: isUpdating, data }] = useAddPaymentMethodMutation()

    const onClickPay = async (e: unknown) => {
        // @ts-ignore
        e.preventDefault();

        await tracker.track('checkout', 'stripe_pay_start', checkoutId)
        await tracker.track('checkout', 'payment_button_click', checkoutId)

        submitForm();

        if (!stripe || !elements) {
            await tracker.track('checkout', 'fail_load_stripe', checkoutId)
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const {ref} = await addPaymentMethod({
            paymentMethod: PaymentMethod.STRIPE,
            id: checkoutId
        }).unwrap();


        let element = elements.getElement('card');

        await tracker.track('checkout', 'stripe_start_confirm_card', checkoutId)

        const {error} = await stripe.confirmCardPayment(ref, {
            payment_method: {
                card: element!
            },
            receipt_email:email
        });

        await tracker.track('checkout', 'stripe_pay_select', checkoutId)

        console.log(error);

        if(error) {
            await tracker.track('checkout', 'stripe_pay_error', JSON.stringify({checkoutId:checkoutId,error:error.message,type:error.type}))

            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message!!);
            } else {
                setMessage("An unexpected error occured.");
            }
        }
        else{
            onPaymentConfirmed();
        }

        setIsLoading(false);

    }


    return (

        <section>
            <h2>Dati di pagamento</h2>
            <div className="payment-info visible">
                <fieldset>
                    <label>
                        <span>Carta</span>
                        <CardElement id="card-element" className={"field"} options={{
                            hidePostalCode:true
                        }}/>
                    </label>
                </fieldset>

            </div>

            {message && <div id="card-errors" className={"cards-errors"}>{message}</div>}


            <button className="stripe submit payment-button" onClick={onClickPay} disabled={disabled}>
                {isLoading && <div className="spinner"/>}
                {!isLoading && <span className="button-text">{`Paga ${amount} €`}</span>}
            </button>





        </section>
    )
}

export default StripePaymentElement;
