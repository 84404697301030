import {getIpInfo, useAuditMutation} from "./api";
import {useCallback} from "react";


export const useTracker = () => {

    const [audit] = useAuditMutation();


    const track = useCallback(
        async (area: string, event: string, data?: string) => {

            try {
                const resp = await getIpInfo();

                // @ts-ignore
                if(window.dataLayer) { // @ts-ignore
                    window.dataLayer.push({
                        event: event, // Nome dell'evento
                        area: area, // Informazioni aggiuntive (opzionale)
                    });
                }

                audit({
                    device: navigator.userAgent,
                    ip: resp.ip,
                    event: event,
                    area: area,
                    data: data
                })
            } catch (e) {
                console.log(e)
            }
        },
        [audit, getIpInfo],
    );
    const trackWith = useCallback(
        async (area: string, event: string, data?: any) => {

            // @ts-ignore
            if(window.dataLayer) { // @ts-ignore
                    window.dataLayer.push({
                                        event: event, // Nome dell'evento
                        area: event, // Informazioni aggiuntive (opzionale)
                                    });
                }

            try {
                const resp = await getIpInfo();
                audit({
                    device: navigator.userAgent,
                    ip: resp.ip,
                    event: event,
                    area: area,
                    data: JSON.stringify(data)
                })
            } catch (e) {
                console.log(e);
            }
        },
        [audit, getIpInfo],
    );

    return {
        track,
        trackWith
    };
}
